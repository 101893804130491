<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="列名" prop="columnName">
        <el-input
          v-model="queryParams.columnName"
          placeholder="请输入要脱敏的列名"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="列名描述" prop="columnDesc">
        <el-input
          v-model="queryParams.columnDesc"
          placeholder="请输入列名描述"
          clearable
          size="small"
          @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">{{ $t('重置') }}</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
          type="success"
          plain
          icon="el-icon-edit"
          size="mini"
          :disabled="single"
          @click="handleUpdate"
          v-hasPermi="['system:mask:edit']"
        >{{ $t('修改') }}</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="handleExport"
          v-hasPermi="['system:mask:export']"
        >{{ $t('导出') }}</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="dataList" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center" />
      <el-table-column label="要脱敏的列名" align="center" prop="columnName" />
      <el-table-column label="列名描述" align="center" prop="columnDesc" />
      <el-table-column label="脱敏方向" align="center" prop="direction">
        <template slot-scope="scope">
          <span v-if="scope.row.direction==0">从左向右</span>
          <span v-if="scope.row.direction==1">从右向左</span>
        </template>
      </el-table-column>
      <el-table-column label="脱敏起始位置" align="center" prop="startIndex" />
      <el-table-column label="脱敏字符数" align="center" prop="wordNum" />
      <el-table-column label="状态" align="center" prop="status">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.status"
            :active-value="1"
            :inactive-value="0"
            @change="handleStatusChange(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" align="center" prop="createTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleUpdate(scope.row)"
            v-hasPermi="['system:mask:edit']"
          >{{ $t('修改') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />

    <!-- 添加或修改脱敏数据对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="700px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="列名" prop="columnName">
          <el-input v-model="form.columnName" readonly :disabled="true" placeholder="请输入要脱敏的列名" />
        </el-form-item>
        <el-form-item label="列名描述" prop="columnDesc">
          <el-input v-model="form.columnDesc" placeholder="请输入列名描述" />
        </el-form-item>
        <el-form-item label="脱敏方向" prop="direction">
          <el-radio-group v-model="form.direction" size="medium">
            <el-radio
              v-for="(item, index) in directionOptions"
              :key="index"
              :label="item.value"
              :disabled="item.disabled">{{ item.label }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="起始位置" prop="startIndex">
          <el-input v-model="form.startIndex" :maxlength="2" placeholder="请输入脱敏起始位置" />
        </el-form-item>
        <el-form-item label="脱敏字符数" prop="wordNum">
          <el-input v-model="form.wordNum" :maxlength="2" placeholder="请输入脱敏字符数" />
        </el-form-item>
        <el-form-item label="是否开启" prop="status" >
          <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" placeholder="请输入备注" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listData, getData, delData, addData, updateData, changeMaskStatus } from '@/api/system/mask'
import { changeJobStatus } from '@/api/monitor/job'

export default {
  name: 'Data',
  data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 脱敏数据表格数据
      dataList: [],
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        columnName: null,
        columnDesc: null,
        direction: null,
        startIndex: null,
        wordNum: null,
        status: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        version: null
      },
      // 表单参数
      form: {},
      directionOptions: [{
        'label': '从左向右',
        'value': 0
      }, {
        'label': '从右向左',
        'value': 1
      }],
      // 表单校验
      rules: {
        columnName: [
          { required: true, message: '要脱敏的列名不能为空', trigger: 'blur' }
        ],
        columnDesc: [
          { required: true, message: '列名描述不能为空', trigger: 'blur' }
        ],
        direction: [{
          required: true,
          message: '脱敏方向不能为空',
          trigger: 'change'
        }],
        startIndex: [
          { required: true, message: '脱敏起始位置不能为空', trigger: 'blur' }
        ],
        wordNum: [
          { required: true, message: '脱敏字符数不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.getList()
  },
  methods: {
    /** 查询脱敏数据列表 */
    getList() {
      this.loading = true
      listData(this.queryParams).then(response => {
        this.dataList = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    // 任务状态修改
    handleStatusChange(row) {
      const text = row.status === '1' ? '启用' : '停用'
      this.$modal.confirm('确认要"' + text + '"' + row.columnDesc + '"吗？').then(function() {
        return changeMaskStatus(row.id, row.status)
      }).then(() => {
        this.$message.success(text + '成功')
      }).catch(function() {
        row.status = row.status === '0' ? '1' : '0'
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.form = {
        id: null,
        columnName: null,
        columnDesc: null,
        direction: null,
        startIndex: null,
        wordNum: null,
        status: null,
        creator: null,
        createTime: null,
        modifier: null,
        updateTime: null,
        isDeleted: null,
        version: null,
        remark: null
      }
      this.resetForm('form')
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm('queryForm')
      this.handleQuery()
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset()
      this.open = true
      this.title = '添加脱敏数据'
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset()
      const id = row.id || this.ids
      getData(id).then(response => {
        this.form = response.data
        this.open = true
        this.title = '修改脱敏数据'
      })
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.form.id != null) {
            updateData(this.form).then(response => {
              this.$message.error('修改成功')
              this.open = false
              this.getList()
            })
          } else {
            addData(this.form).then(response => {
              this.$message.error('新增成功')
              this.open = false
              this.getList()
            })
          }
        }
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download('mask/data/export', {
        ...this.queryParams
      }, `data_${new Date().getTime()}.xlsx`)
    }
  }
}
</script>
